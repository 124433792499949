import { createErrorNotification, setAppError } from '@/context/Error';

import config from '@/lib/config';
import { replaceRoute } from '@/lib/history';
import NetworkClient from '@/lib/network/client';
import route from '@/lib/routes';

const sessionCache = {};

export function setAuthToken(accessToken) {
  if (!sessionCache.accessToken) {
    sessionCache.accessToken = accessToken;
  }
}

export function removeAuthToken() {
  if (sessionCache.accessToken) {
    delete sessionCache['accessToken'];
  }
}

// Deskpass Server API network client

const baseURL = `${config.API_URL}/api`;

export class DeskpassNetworkClient extends NetworkClient {
  config = {
    baseURL,

    transformData: ({ data } = {}) => data?.result,

    includeHeaders: () => ({
      ...(sessionCache.accessToken && {
        Authorization: `Bearer ${sessionCache.accessToken}`,
      }),
    }),

    onError: (error) => {
      const {
        error: { error: errorCode, displayError },
        status,
      } = error;

      if (status === 403) {
        return setAppError((ErrorTypes) => ErrorTypes.Forbidden);
      }

      // If user is unathorized, their access token is probably bad/expired, so
      // log them out and kick them back to the login
      if (status === 401) {
        // Handle cases where token get invalid for some reason
        if (errorCode === 'invalidCredentials') {
          createErrorNotification(displayError);

          return replaceRoute(route('logout'));
        }
      }
    },
  };
}

export default new DeskpassNetworkClient();
