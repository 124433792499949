import { createContext, useContext, useMemo, useState } from 'react';

import useEvent from '@/hooks/useEvent';

import { consumerToHOC } from '@/lib/hoc';

const Context = createContext({});

export function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState([]);
  const [persistentNotifications, setPersistentNotifications] = useState([]);

  const createOrReplacePersistent = useEvent(
    (name, message, style = null, options = {}) => {
      const { url = null } = options;

      const notification = {
        message,
        style,
        id: name,
        url,
      };

      setPersistentNotifications((prev) => {
        const idx = prev.findIndex((it) => it.id === name);

        if (idx !== -1) {
          // Replace existing notification
          const updated = [...prev];
          updated[idx] = notification;
          return updated;
        } else {
          // Add new notification to the top
          return [notification, ...prev];
        }
      });
    },
  );

  const destroyPersistent = useEvent((id) => {
    setPersistentNotifications((prev) => prev.filter((it) => it.id !== id));
  });

  const create = useEvent((message, style = null, options = {}) => {
    if (notifications.some((it) => it.message === message)) return;

    const { timeout = 6000, url = null } = options;
    const id = btoa(Math.random()).substring(0, 12);
    const notification = { message, style, id, timeout, url };

    setNotifications((prev) => [...prev, notification]);

    // Automatically destroy notification after timeout
    setTimeout(() => {
      destroy(id);
    }, timeout);

    return notification;
  });

  const destroy = useEvent((id) => {
    setNotifications((prev) => prev.filter((it) => it.id !== id));
  });

  const context = useMemo(
    () => ({
      notifications,
      persistentNotifications,
      createOrReplacePersistent,
      destroyPersistent,
      create,
      destroy,
    }),
    [
      notifications,
      persistentNotifications,
      createOrReplacePersistent,
      destroyPersistent,
      create,
      destroy,
    ],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export default Context;
export const useNotificationContext = () => useContext(Context);
export const withNotificationContext = consumerToHOC(
  Context.Consumer,
  'notificationContext',
);
