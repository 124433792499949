import { createBrowserHistory } from 'history';

import { safeAccess } from '@/lib/browser';

const isServer = typeof window === 'undefined';

//
//   Create single instace of browser history wrapper
//
//////////////////////////////////////////////////////////////////////
const history = isServer ? safeAccess() : createBrowserHistory();

// Export some explicit methods for the benefit of being able to
// require it directly
const replaceRoute = history.replace;

export { replaceRoute };

export default history;
