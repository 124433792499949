import { Component, forwardRef, memo, useEffect, useRef } from 'react';

import LoadingContent from '@/components/helpers/LoadingContent';

import { useExistingOrNewRef } from '@/hooks/ref';

import config from '@/lib/config';
import { capitalize } from '@/lib/stringHelpers';

const isDev = config.NODE_ENV === 'development';

const identity = (x) => x;

/*
 * Helper that gets a displayName from a Component
 * This helps build displayNames for components wrapped in HOCs
 */
export const getDisplayName = (Component) => {
  if (typeof Component === 'string') {
    return Component;
  }

  if (!Component) {
    return undefined;
  }

  return Component.displayName || Component.name || 'Component';
};

/*
 * HOC that sets displayName on a Component wrapped by HOCs for debugging purposes.
 * It will only set displayName when running development mode.
 */
export const setDisplayName =
  (Component, hocName = '') =>
  (BaseComponent) => {
    let displayName = getDisplayName(Component);
    if (hocName) {
      displayName = `${hocName}(${displayName})`;
    }

    if (isDev) {
      BaseComponent.displayName = displayName;
    }

    return BaseComponent;
  };

/*
 * Compose Hocs together to form a new hoc
 */
export const compose = (...funcs) =>
  funcs.reduce(
    (a, b) =>
      (...args) =>
        a(b(...args)),
    (arg) => arg,
  );

/*
 * HOC that expects a callback that will receive props and should return a boolean value
 * The BaseComponent will be rendered when the returned value is true or not otherwise
 */
export const shouldRender =
  (test = () => true) =>
  (BaseComponent) => {
    const ShouldRender = (props) => {
      if (test(props)) {
        return <BaseComponent {...props} />;
      }

      return null;
    };

    return setDisplayName(BaseComponent, 'shouldRender')(ShouldRender);
  };

/*
 * HOC that tests a condition and decides wether to render the BaseComponent or another one
 *
 * @param {Function}     test => This callback function receives props and should return boolean.
 *                       - Truthy values means it'll render BranchedComponent.
 *                       - Falsy values means it'll render BaseComponent.
 * @param {Component}    BranchedComponent => Component rendered when the test function returns truthy
 *
 * @return {Function}    hocWrapper => Gets the Base Component and render a Wrapper Component that then
 *                       renders the BaseComponet back as any HOC.
 */
export const branch =
  (test = () => true, BranchedComponent) =>
  (BaseComponent) => {
    const Branch = (props) => {
      if (test(props)) {
        return <BranchedComponent {...props} />;
      }

      return <BaseComponent {...props} />;
    };

    return setDisplayName(BaseComponent, 'branch')(Branch);
  };

/*
 * HOC that expects a callback that will receive props and should return a boolean value
 * The BaseComponent will be rendered when the returned value is true or not otherwise
 */
export const renderLoadingWhen =
  (test = () => true, renderNeutralSpinner = () => false) =>
  (BaseComponent) => {
    const RenderLoadingWhen = (props) => {
      if (test(props)) {
        const neutralIfNotReady = renderNeutralSpinner(props)
          ? 'neutral'
          : undefined;
        return <LoadingContent $placement={neutralIfNotReady} />;
      }

      return <BaseComponent {...props} />;
    };

    return setDisplayName(
      BaseComponent,
      'renderLoadingWhen',
    )(RenderLoadingWhen);
  };

/*
 * Connect hook through HOC api for legacy stuff in class components
 */
export const makeWithHook =
  (hook, propName = 'hook') =>
  (hookMapper = () => []) =>
  (BaseComponent) => {
    const WithHook = (props) => {
      const hookProps = {
        [propName]: hook(...hookMapper(props)),
      };

      return <BaseComponent {...props} {...hookProps} />;
    };

    return compose(
      // Use memo to avoid re-renders of wrapped component
      // Due to setState calls that don't change any value
      // This will enforce the need of immutability on state
      // changes on the Context Providers
      memo,
      setDisplayName(BaseComponent, `with${capitalize(propName)}`),
    )(WithHook);
  };

/*
 * Expects a React Context.Consumer and a field to represent the name of the context variable
 * that will be injected in the BaseComponent.
 *
 * Returns a HOC that injects the Context of that consumer on the BaseComponent.
 */
export const consumerToHOC =
  (Consumer, propName = 'context') =>
  (BaseComponent) => {
    const ConsumerToHOC = (props) => (
      <Consumer>
        {(context) => {
          const contextProps = {
            [propName]: context,
          };

          return <BaseComponent {...props} {...contextProps} />;
        }}
      </Consumer>
    );

    return compose(
      // Use memo to avoid re-renders of wrapped component
      // Due to setState calls that don't change any value
      // This will enforce the need of immutability on state
      // changes on the Context Providers
      memo,
      setDisplayName(BaseComponent, `with${capitalize(propName)}`),
    )(ConsumerToHOC);
  };

/*
 * Call handler when component mounts
 */
export const onMount = (onMountHandler) => (BaseComponent) => {
  class OnMount extends Component {
    componentDidMount() {
      if (typeof onMountHandler === 'function') {
        onMountHandler(this.props);
      }
    }

    render() {
      return <BaseComponent {...this.props} />;
    }
  }

  return setDisplayName(BaseComponent, 'onMount')(OnMount);
};

/*
 * Handle Props Change
 */
export const onPropsChange = (onChangeHandler) => (BaseComponent) => {
  class OnPropsChange extends Component {
    componentDidUpdate(prevProps) {
      if (typeof onChangeHandler === 'function') {
        onChangeHandler(prevProps, this.props);
      }
    }

    render() {
      return <BaseComponent {...this.props} />;
    }
  }

  return setDisplayName(BaseComponent, 'onPropsChange')(OnPropsChange);
};

/*
 * Map received props to the props that will be injected in the BaseComponent
 */
export const mapProps =
  (mapper = identity) =>
  (BaseComponent) => {
    const MapProps = (props) => {
      const mappedProps = mapper(props);
      return <BaseComponent {...mappedProps} />;
    };

    return setDisplayName(BaseComponent, 'mapProps')(MapProps);
  };

/*
 * Holds one state value in a HOC chaing and inject a state variable and a setState function
 * that you can name it whatever you will
 *
 * Arguments
 * - stateName => Name of the state variable that will be injected as prop
 * - stateUpdaterName => Name of the function variable that updates the state and that will be injected as prop
 * - initial state => Initial valur of the variable or function that receive props and compute the initial value of the state
 */
export const withState =
  (stateName, stateUpdaterName, initialState) => (BaseComponent) => {
    class WithState extends Component {
      state = {
        stateValue:
          typeof initialState === 'function'
            ? initialState(this.props)
            : initialState,
      };

      updateStateValue = (updateFn, callback) => {
        this.setState(
          ({ stateValue }) => ({
            stateValue:
              typeof updateFn === 'function' ? updateFn(stateValue) : updateFn,
          }),
          callback,
        );
      };

      render() {
        const hocProps = {
          [stateName]: this.state.stateValue,
          [stateUpdaterName]: this.updateStateValue,
        };

        return <BaseComponent {...this.props} {...hocProps} />;
      }
    }

    return setDisplayName(BaseComponent, 'withState')(WithState);
  };

/*
 * Inject data loaded from the API on the BaseComponent.
 */
export const withApiData =
  ({
    loader: createLoader,
    propName,
    loadIf,
    reloadWhen,
    initialState,
    onSuccess = () => () => null,
    onError = () => () => null,
  }) =>
  (BaseComponent) => {
    class WithApiData extends Component {
      constructor(props) {
        super(props);

        this.state = {
          data: null,
          error: null,
          loading: false,
          ready: false,
          ...this.getInitialState(),
        };
      }

      componentDidMount() {
        // Attempt to load data on mount if there's nothing to wait for
        if (typeof loadIf === 'function') {
          if (loadIf(this.props)) {
            this.loadInitialData();
          }
        } else {
          this.loadInitialData();
        }
      }

      componentDidUpdate(prevProps) {
        const shouldLoad =
          typeof loadIf === 'function' &&
          !loadIf(prevProps) &&
          loadIf(this.props);

        // Loads data when loadIf becomes true
        if (!this.state.ready && shouldLoad) {
          this.loadInitialData();
        }

        const relaodWhenChanged =
          typeof reloadWhen === 'function' && reloadWhen(prevProps, this.props);

        // Reload data when reloadWhen returns true
        if (this.state.ready && relaodWhenChanged) {
          this.loadInitialData();
        }
      }

      render() {
        const hocProps = {
          [propName || 'apiData']: {
            ...this.state,
            load: this.loadData,
            set: this.setData,
          },
        };

        return <BaseComponent {...this.props} {...hocProps} />;
      }

      getInitialState = () => {
        if (typeof initialState === 'function') {
          return initialState(this.props) || {};
        }

        return {};
      };

      setData = (data) => {
        return new Promise((resolve) => {
          this.setState({ data }, () => {
            resolve(data);
          });
        });
      };

      loadInitialData = (...args) => {
        this.loadData(...args).catch((err) => {
          console.error(
            `withApiData HOC failed to load initial data for ${propName} with error: `,
            err.errorCode,
          );
        });
      };

      loadData = (...args) => {
        this.setState({ loading: true });

        const loader = createLoader(this.props);
        const loaderPromise = loader(...args);
        // Handle response
        return new Promise((resolve, reject) => {
          loaderPromise
            .then((...args) => {
              onSuccess(this.props)(...args);
              this.setState({ error: null });
              this.setData(...args);
            })
            .catch((error) => {
              onError(this.props)(error);
              this.setState({ error });
            })
            .finally(() => {
              this.setState({ loading: false, ready: true }, () => {
                if (this.state.error) {
                  reject(this.state.error);
                } else {
                  resolve(this.state.data);
                }
              });
            });
        });
      };
    }

    return setDisplayName(
      BaseComponent,
      `with${capitalize(propName)}Data`,
    )(WithApiData);
  };

export const onClickOutside = (BaseComponent) => {
  const OnClickOutside = forwardRef(({ onClickOutside, ...rest }, ref) => {
    const selfRef = useRef({});
    const existingOrNewRef = useExistingOrNewRef(ref, (domEl) => {
      selfRef.current.domEl = domEl;
    });

    useEffect(() => {
      selfRef.current.handleClickOutside = onClickOutside;
    });

    useEffect(() => {
      const clickHandler = (e) => {
        const { domEl, handleClickOutside } = selfRef.current;

        if (!domEl.contains(e.target)) {
          handleClickOutside(e, domEl);
        }
      };

      document.addEventListener('click', clickHandler);
      return () => {
        document.removeEventListener('click', clickHandler);
      };
    }, []);

    return <BaseComponent {...rest} ref={existingOrNewRef} />;
  });

  return setDisplayName(BaseComponent, 'onClickOutside')(OnClickOutside);
};
