import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import pick from 'lodash/pick';

import Driver from '@/lib/network/drivers/Driver';
import {
  bodylessMethods,
  isRedirect,
  isRequestCancel,
} from '@/lib/network/utils';
import qs from '@/lib/qs';

/*
 * Driver instance adapting requests to use the fetch API
 */
const fetchDriver = new Driver({
  getInstance() {
    return fetch;
  },

  requestMapper({ getInstance, config, method }) {
    return _makeRequestCaller(getInstance, { method, ...config });
  },
});

export default fetchDriver;

// Private Functions //

const fetchOptions = [
  'method',
  'headers',
  'body',
  'mode',
  'credentials',
  'cache',
  'redirect',
  'referrer',
  'referrerPolicy',
  'integrity',
  'keepalive',
  'signal',
  'priority',
];

const _makeUrl = (url = '', params = {}) => {
  if (isEmpty(params)) {
    return url;
  }

  return `${url}?${qs.stringify(params)}`;
};

function _makeRequestCaller(getRequestEngine, _config = {}) {
  let { method = 'GET', ...baseConfig } = _config;

  method = method.toUpperCase();

  return async (_url = '', ...args) => {
    let config, body;

    if (bodylessMethods.includes(method.toLowerCase())) {
      config = args[0];
    } else {
      body = args[0];
      config = args[1];
    }

    const { params, ...argsConfig } = config ?? {};

    let extraConfig = {};

    const hasBody = body && method !== 'GET';
    const isJsonBody = hasBody && isPlainObject(body);
    const isMultipartBody = hasBody && body instanceof FormData;

    if (isJsonBody) {
      extraConfig.body = JSON.stringify(body);
      extraConfig.headers = {
        'Content-Type': 'application/json',
      };
    }

    if (isMultipartBody) {
      extraConfig.body = body;
    }

    const url = _makeUrl(_url, params);

    const requestHeaders = {
      ...(baseConfig.headers ?? {}),
      ...(extraConfig.headers ?? {}),
      ...(argsConfig.headers ?? {}),
    };

    const requestOptions = pick(
      {
        method,
        ...baseConfig,
        ...extraConfig,
        ...argsConfig,
        ...(!isEmpty(requestHeaders) && { headers: requestHeaders }),
      },
      fetchOptions,
    );

    const request = new Request(url, requestOptions);

    let result = {
      request,
      config: {
        method,
        ...baseConfig,
        ...argsConfig,
      },
    };

    let response;

    try {
      const _fetch = getRequestEngine();
      response = await _fetch(request);
    } catch (err) {
      result.error = {
        name: err.name,
        message: 'The request failed',
        originalError: err,
      };

      if (isRequestCancel(err)) {
        result.error.message = 'The request has been cancelled';
      }

      throw result;
    }

    const { headers, status, statusText, type } = response;

    result = {
      status,
      statusText,
      headers,
      type,
      ...result,
    };

    let parsedResponse;

    try {
      parsedResponse = await response.json();
    } catch (err) {
      result.error = {
        name: err.name,
        message: statusText,
        originalError: err,
      };
    }

    // This should exclude redirects as they are not errors
    if (!response?.ok && !isRedirect(response)) {
      result.error = result.error ?? parsedResponse;
      throw result;
    }

    result.data = parsedResponse;

    return result;
  };
}
